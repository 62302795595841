<template>
  <sub-page
      v-model="$store.state.settings.pageData"
      :title="$t('EducationPrograms')"
      icon="mdi-account"
      :header-action="openNewEntity"
      :header-action-text="$t('wsu.education.education_program.add')"
      :no-content-condition="items.length === 0"
      :no-content-text="$t('wsu.education.education_program.no_content')"
      :no-content-button-action="openNewEntity"
      :no-content-button-text="$t('wsu.education.education_program.add')"
      fill-height

      :filters="filters"


  >

    <template #default>

      <ws-data-table
          :items="itemsFiltered"
          :headers="headers"
          :search="filtersData.search"
          class="mt-5"
          :context-actions-select="contextActionsSelect"
          @itemSelect="selectedItem = $event"
          :row-action="($event) => { $router.push(businessDashLink(`education/education_programs/${$event.uuid}`))}"
      >

        <template #item.date="{item}">
          <h5 class="text-no-wrap"> {{ MONTH_DAY_YEAR( item.date )}}</h5>
        </template>

        <template #item.name="{item}">
          <h5 :style="`color : ${wsACCENT}`">{{ item.name || item.specialty_name }}</h5>
          <h5> {{ item.department_name }}</h5>
        </template>

        <template #item.degree_name="{item}">
          <h5> {{ item.degree_name }}</h5>
        </template>

        <template #item.semesters="{item}">
          <h5 class="text-center"> {{ item.semesters }}</h5>
        </template>

      </ws-data-table>
    </template>

    <template #dialog>

      <!-- Add Edit Entity-->
      <ws-dialog
          v-model="displayDialog"
          :title="$t(!editEntity? 'wsu.education.education_program.add' :  'wsu.education.education_program.edit')"
          @save="addEntity"
          @delete="deleteEntity"
          :confirm-delete-code="selectedItem ? selectedItem.uuid : null"
          :show-delete="editEntity"
      >

        <ws-text-field
            v-model="entityData.name"
            :title="$t('WorkName')"
            :placeholder="$t('wsu.education.education_program.work_name.placeholder')"
            clearable
        />

        <ws-select
            class="mt-6 mb-6"
            @input="entityData.degree_id = null; entityData = COPY(entityData) ; universitySpecialtyError = null; degreeError = null"
            v-model="entityData.university_specialty_id"
            :items="universitySpecialtiesSelect"
            :label="$t('Specialty')"
            :placeholder="$t('wsu.structure.specialty.choose')"
            clearable
            autocomplete
            :error="universitySpecialtyError"
        />

        <ws-select
            v-model="entityData.degree_id"
            :items="degreesSelect"
            :label="$t('Degree')"
            :placeholder="$t('wsu.structure.degree.choose')"
            :error="degreeError"
            @input="degreeError = null"
        />

        <v-row no-gutters>
          <v-col cols="6" class="pr-2">
            <ws-select
                v-model="entityData.semesters_per_year"
                :items="academicPeriodsSelect"
                :label="$t('AcademicPeriod')"
                :placeholder="$t('wsu.education.academic_period.choose')"
            />
          </v-col>
          <v-col cols="6" class="pl-2">
            <ws-select
                v-model="entityData.semesters"
                :items="semestersSelect"
                :label="academicPeriodsAmountLabel"
                :placeholder="academicPeriodsAmountPlaceholder"
                :error="semestersError"
                @input="semestersError = null"
                autocomplete
            />

          </v-col>
        </v-row>

        <ws-text-field
            v-model="entityData.credits"
            :label="$t('wsu.education.education_program.credits.label')"
            :placeholder="$t('wsu.education.education_program.credits.placeholder')"
            number-length="3"
            number
        />

        <ws-date-picker
            class="mt-5"
            v-model="entityData.date"
            :label="$t('Date')"
            :placeholder="$t('ChooseDate')"
            :time-stamp="editEntity"
        />

      </ws-dialog>
      <!-- Delete Entity Entity-->
      <ws-dialog
          v-if="displayDeleteDialog"
          v-model="displayDeleteDialog"
          @delete="deleteEntity"
          :title="$t('wsu.education.education_program.delete')"
          display-confirm-delete
          :confirm-delete-code="selectedItem ? selectedItem.uuid : null"
          close-delete
      />


    </template>

  </sub-page>
</template>

<script>
import {mapActions, mapState} from "vuex";

export default {
  name: "educationPrograms",
  data() {
    return {
      items : [],
      entityData : {},
      displayDialog : false,
      displayDeleteDialog : false,
      selectedItem : {},
      editEntity : false,

      universitySpecialtyError : false,
      degreeError : false,
      semestersError : false,

      universitySpecialtiesSelect : [],
      facultiesSelect : [],
      institutesSelect : [],
      departmentsSelect : [],
      degreesSelectArray : [],
      semestersSelect : [
        { text : 1 , value : 1 },
        { text : 2 , value : 2 },
        { text : 3 , value : 3 },
        { text : 4 , value : 4 },
        { text : 5 , value : 5 },
        { text : 6 , value : 6 },
        { text : 7 , value : 7 },
        { text : 8 , value : 8 },
        { text : 9 , value : 9 },
        { text : 10 , value : 10 },
        { text : 11 , value : 11 },
        { text : 12 , value : 12 },
        { text : 13 , value : 13 },
        { text : 14 , value : 14 },
        { text : 15 , value : 15 },
        { text : 16 , value : 16 },
        { text : 17 , value : 17 },
        { text : 18 , value : 18 },
        { text : 19 , value : 19 },
        { text : 20 , value : 20 },
        { text : 21 , value : 21 }
      ]
    }
  },
  computed : {
    ...mapState('settings' , ['filtersData']),
    headers() {
      return [
        { text : this.$t('Date') , value : 'date' , width : 100 },
        { text : this.$t('Name') , value : 'name' },
        { text : this.$t('Semesters') , value : 'semesters' ,width: 10 , align : 'center' },
        { text : this.$t('Degree') , value : 'degree_name' , width : 140  },
        { value : 'specialty_name' , isHidden : true , align : 'd-none' },

      ]
    },
    itemsFiltered() {
      let items = this.COPY(this.items)

      // Specialty filter
      if ( this.filtersData.specialty && Array.isArray(this.filtersData.specialty) && this.filtersData.specialty.length > 0 ) {
        items = items.filter( el => this.filtersData.specialty.includes(el.university_specialty_id) )
      }
      // Degree filter
      if ( this.filtersData.degree && Array.isArray(this.filtersData.degree) && this.filtersData.degree.length > 0 ) {
        items = items.filter( el => this.filtersData.degree.includes(el.degree_id) )
      }
      // Department filter
      if ( this.filtersData.department && Array.isArray(this.filtersData.department) && this.filtersData.department.length > 0 ) {
        items = items.filter( el => this.filtersData.department.includes(el.department_id) )
      }
      // Faculty filter
      if ( this.filtersData.faculty && Array.isArray(this.filtersData.faculty) && this.filtersData.faculty.length > 0 ) {
        items = items.filter( el => this.filtersData.faculty.includes(el.faculty_id) )
      }
      // Institute filter
      if ( this.filtersData.institute && Array.isArray(this.filtersData.institute) && this.filtersData.institute.length > 0 ) {
        items = items.filter( el => this.filtersData.institute.includes(el.institute_id) )
      }

      return items
    },
    filters() {
      return [
        { text : this.$t('Specialty') , value : 'specialty' , type : 'select_multiple' , items : this.universitySpecialtiesSelect },
        { text : this.$t('Degree') , value : 'degree' , type : 'select_multiple' , items : this.degreesSelectArray },
        { text : this.$t('Department') , value : 'department' , type : 'select_multiple' , items : this.departmentsSelect },
        { text : this.$t('Faculty') , value : 'faculty' , type : 'select_multiple' , items : this.facultiesSelect },
        { text : this.$t('Institute') , value : 'institute' , type : 'select_multiple' , items : this.institutesSelect },
      ]
    },
    academicPeriodsAmountLabel() {
      if ( this.entityData.semesters_per_year === 2 ) {
        return this.$t('wsu.education.academic_period.semester.total_amount')
      }
      if ( this.entityData.semesters_per_year === 3 ) {
        return this.$t('wsu.education.academic_period.trimester.total_amount')
      }
      if ( this.entityData.semesters_per_year === 4 ) {
        return this.$t('wsu.education.academic_period.quarter.total_amount')
      }
      return 'wsu.education.academic_period.choose_amount'
    },
    academicPeriodsAmountPlaceholder() {
      if ( this.entityData.semesters_per_year === 2 ) {
        return this.$t('wsu.education.academic_period.semester.enter_amount')
      }
      if ( this.entityData.semesters_per_year === 3 ) {
        return this.$t('wsu.education.academic_period.trimester.enter_amount')
      }
      if ( this.entityData.semesters_per_year === 4 ) {
        return this.$t('wsu.education.academic_period.quarter.enter_amount')
      }
      return ''
    },
    academicPeriodsSelect() {
      return [
        { text : this.$t('Semester') , value : 2 },
        { text : this.$t('Trimester') , value : 3 },
        { text : this.$t('Quarter') , value : 4 }
      ]
    },
    degreesSelect() {

      if (!this.entityData.university_specialty_id ) {
        return []
      }

      let universitySpecialty = this.universitySpecialtiesSelect.find(el => el.value === this.entityData.university_specialty_id)

      if ( !universitySpecialty ) {
        return []
      }

      return universitySpecialty.degrees

    },
    contextActionsSelect() {
      return [
        { text : this.$t('Edit') ,
          value : 'edit' , icon : 'mdi-pencil' ,
          action : () => { this.openEditEntity() } },
        { text : this.$t('Delete') ,
          value : 'delete' ,
          icon : 'mdi-delete-outline' ,
          action : () => { this.displayDeleteDialog = true }
        }
      ]
    },
  },
  methods : {
    ...mapActions('wsu_education' , [
        'GET_EDUCATION_PROGRAMS' ,
        'ADD_EDIT_EDUCATION_PROGRAM' ,
        'DELETE_EDUCATION_PROGRAM'
    ]),

    async addEntity() {

      if ( !this.entityData.university_specialty_id ) {
        this.universitySpecialtyError = true
        return this.notify(this.$t('wsu.structure.specialty.choose') , 'warning')
      }

      if ( !this.entityData.degree_id ) {
        this.degreeError = true
        return this.notify(this.$t('wsu.structure.degree.choose') , 'warning')
      }
      this.entityData.topic_name = this.$t('EducationProgramTopic')
      this.entityData.lang = this.selectedLang || 'ua'
      let result = await this.ADD_EDIT_EDUCATION_PROGRAM(this.entityData)
      if ( !result ) {
        this.notify(this.$t('NetworkError'))
        return
      }
      if ( !this.editEntity ) {
        this.items.push(result)
        this.notify(this.$t('wsu.education.education_program.created') , 'success')
      } else {
        let index = this.items.findIndex(el => el.uuid === result.uuid )
        if (index === -1 ) {
          return
        }
        this.items[index] = result
        this.items = this.COPY(this.items)
        this.notify(this.$t('ChangesSaved'))
      }

      this.displayDialog = false
    },
    async deleteEntity() {
      let result = await this.DELETE_EDUCATION_PROGRAM(this.selectedItem.uuid)
      if ( !result ) {
        return this.notify(this.$t('NetworkError'))
      }
      let index = this.items.findIndex(el => el.uuid === this.selectedItem.uuid )
      if (index === -1 ) {
        return
      }
      this.items.splice(index , 1)
      this.notify(this.$t('wsu.structure.institute.deleted') )
      this.displayDeleteDialog = false
      this.displayDialog = false

    },

    openNewEntity() {
      this.editEntity = false
      this.entityData = {
        semesters_per_year : 2,
        semesters : 8,
      }
      this.displayDialog = true
    },
    openEditEntity() {
      this.entityData = this.COPY(this.selectedItem)
      this.editEntity = true
      this.displayDialog = true
    },

    async initPage() {
      let result = await this.GET_EDUCATION_PROGRAMS()
      this.items = result ? result.education_programs : []

      this.universitySpecialtiesSelect = result ? result.university_specialties_select : []
      this.facultiesSelect =  result.faculties_select || []
      this.institutesSelect =  result.institutes_select || []
      this.departmentsSelect =  result.departments_select || []
      this.degreesSelectArray =  result.degrees_select || []
    },
  },
  mounted() {
    this.initPage()
  }
}
</script>

<style scoped>

</style>